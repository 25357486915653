<template>
  <div class="listBox">
    <pageTitle :pageTitle="title">
      <div slot class="color999">
        视频加密后，观看端播放加密视频，详细介绍请查看
        <span class="msgBlue" @click="openTip">《视频加密介绍》</span>
      </div>
    </pageTitle>
    <div class="noData" v-if="no_show === true">
      <null-page text="暂未创建回放" nullType="noAuth">
        <vh-button class="length152 createBtn" round type="primary" @click="toCreate">
          创建回放
        </vh-button>
        <vh-button
          plain
          v-if="WEBINAR_PES.btn_record && !isEmbed"
          class="length152 transparent-btn"
          round
          type="white-primary"
          @click="toRecord"
        >
          录制
        </vh-button>
        <!-- <vh-button type="white-primary" class="length152" round @click="openCheckWord" v-if="$route.params.str">资料库</vh-button> -->
      </null-page>
    </div>
    <template v-if="no_show === false">
      <div v-if="!isDemand" class="operaBlock">
        <vh-button size="medium" type="primary" round @click="toCreate">创建回放</vh-button>
        <vh-button size="medium" plain class="transparent-btn" round @click="uploadBut">
          上传
        </vh-button>
        <vh-button
          v-if="WEBINAR_PES.btn_record && !isEmbed"
          class="transparent-btn"
          size="medium"
          plain
          round
          @click="toRecord"
        >
          录制
        </vh-button>
        <vh-button size="medium" class="transparent-btn" round plain @click="settingHandler">
          回放设置
        </vh-button>
        <vh-button
          size="medium"
          class="transparent-btn"
          round
          plain
          :disabled="selectDatas.length < 1"
          @click="deletePlayBack(selectDatas.map(item => item.id).join(','), 1)"
        >
          批量删除
        </vh-button>
        <vh-input
          clearable
          @keyup.enter.native="getSearchList"
          placeholder="请输入内容标题"
          autocomplete="off"
          v-clearEmoij
          class="resetRightBrn"
          @clear="getList"
          v-model="keyWords"
        >
          <i class="vh-icon-search vh-input__icon" slot="prefix" @click="getSearchList"></i>
        </vh-input>
      </div>
      <div :class="['tableBox', isDemand === true ? '' : 'recordTableBox']" v-loading="loading">
        <vh-table
          :header-cell-style="{ background: '#f7f7f7', color: '#666', height: '56px' }"
          v-if="isDemand !== ''"
          ref="playBackTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          @cell-mouse-enter="handleCellMouseEnter"
          @cell-mouse-leave="handleCellMouseLeave"
        >
          <vh-table-column
            v-if="!isDemand"
            type="selection"
            width="55"
            fixed="left"
          ></vh-table-column>
          <!--
            :width="isBidScreen ? 55 : 52" -->
          <vh-table-column :width="isDemand === true ? 375 : 365" label="内容标题">
            <template slot-scope="scope">
              {{ scope.row.date }}
              <div class="content">
                <div class="imageBox">
                  <div class="imageWrap" v-if="scope.row.transcode_status != 1">
                    <p
                      v-if="scope.row.transcode_status == 2"
                      class="statusDesc"
                      @click="reTranscode(scope.row)"
                    >
                      生成失败
                    </p>
                    <p v-else class="statusDesc disabled">
                      {{
                        scope.row.transcode_status == 0 || scope.row.transcode_status == 3
                          ? `生成中...${
                              scope.row.layout != 0 && scope.row.is_union_screen != 1
                                ? scope.row.remake_doc_progress + '%'
                                : ''
                            }`
                          : ''
                      }}
                    </p>
                  </div>
                  <img
                    @click="preview(scope.row)"
                    :src="scope.row.img_url"
                    alt=""
                    style="cursor: pointer"
                  />
                  <span
                    v-if="!isDemand || liveDetailInfo.webinar_type == 5 || scope.row.is_rehearsal"
                    class="defaultSign"
                    :class="scope.row.is_rehearsal ? 'isRehearsal' : ''"
                  >
                    <i
                      v-show="!scope.row.is_rehearsal"
                      @click="setDefault(scope.row)"
                      :class="{ active: scope.row.type == 6 }"
                    ></i>
                    {{ scope.row.is_rehearsal ? '彩排回放' : '默认回放' }}
                  </span>
                  <div v-if="scope.row.encrypt_status == 2" class="ps jiami">加密</div>
                  <div class="ps jiami_zhezhao" v-if="scope.row.encrypt_status == 1">
                    <div class="ps jiamizhong">加密中...</div>
                  </div>
                </div>
                <div class="info">
                  <vh-tooltip
                    :disabled="!isTextOverflow"
                    placement="top-start"
                    :content="scope.row.name"
                  >
                    <div class="videoName custom-tooltip-content">
                      {{ scope.row.name }}
                    </div>
                  </vh-tooltip>

                  <p class="create-time">{{ scope.row.created_at }}</p>
                  <span v-if="scope.row.ai_moment_tag === 1" class="tag">精彩时刻</span>
                  <span v-if="scope.row.ai_summary_tag === 1" class="tag">概要总结</span>
                  <span v-if="scope.row.doc_status && WEBINAR_PES['ui.record_chapter']" class="tag">
                    章节
                  </span>
                  <span v-if="scope.row.layout != 0 && scope.row.is_union_screen != 1" class="tag">
                    重制
                  </span>
                  <span v-if="scope.row.live_subtitle_type > 0" class="tag">
                    <template v-if="scope.row.live_subtitle_type == 1">中字幕</template>
                    <template v-else-if="[3, 4].includes(scope.row.live_subtitle_type)">
                      英字幕
                    </template>
                    <template v-else-if="[2, 5].includes(scope.row.live_subtitle_type)">
                      中英字幕
                    </template>
                  </span>
                </div>
              </div>
            </template>
          </vh-table-column>
          <vh-table-column :width="120">
            <template slot-scope="{ column, $index }" slot="header">
              <vh-select
                popper-class="playback-list-popper"
                v-if="!isDemand"
                v-model="recordType"
                @change="typeChange(column, $index)"
              >
                <vh-option
                  v-for="item in typeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></vh-option>
              </vh-select>
              <span v-else>视频来源</span>
            </template>
            <span class="playpackSource" slot-scope="scope">
              {{ scope.row.source | soruceTotext }}
            </span>
          </vh-table-column>

          <vh-table-column label="时长" :width="isBidScreen ? '' : 91" show-overflow-tooltip>
            <span class="playpackSource" slot-scope="scope">{{ scope.row.duration }}</span>
          </vh-table-column>

          <vh-table-column
            v-if="webinarType == 6"
            label="小组视频"
            :width="isBidScreen ? '' : 108"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <router-link
                v-if="scope.row.group_record_num > 0"
                :to="{
                  path: `${isEmbed ? '/embed' : ''}/live/playback/${webinar_id}/group/${
                    scope.row.switch_id
                  }`,
                  query: {
                    is_rehearsal: scope.row.is_rehearsal
                  }
                }"
                style="color: blue"
              >
                {{ scope.row.group_record_num }}
              </router-link>
              <span v-else>-</span>
            </template>
          </vh-table-column>

          <vh-table-column
            v-if="!isDemand"
            label="布局"
            :width="isBidScreen ? '' : 78"
            show-overflow-tooltip
          >
            <span class="playpackSource" slot-scope="scope">
              {{ scope.row.layout | layoutFilter }}
            </span>
          </vh-table-column>

          <vh-table-column
            v-if="!isDemand"
            label="暂存至"
            :width="isBidScreen ? '' : 110"
            class-name="deltime"
            show-overflow-tooltip
          >
            <span class="playpackSource" slot-scope="scope">{{ scope.row.save_time }}</span>
          </vh-table-column>

          <vh-table-column :width="isBidScreen ? 300 : 286" label="操作" fixed="right" align="left">
            <template slot-scope="scope">
              {{ scope.row.date }}
              <vh-button type="text" @click="editDialog(scope.row)">编辑</vh-button>
              <vh-button v-if="showDownloadButton" type="text" @click="getVideoStatus(scope.row)">
                下载
              </vh-button>
              <vh-button type="text" @click="handleAi(scope.row)">AI创作</vh-button>
              <vh-button
                v-if="scope.row.is_rehearsal"
                type="text"
                @click="deletePlayBack(scope.row.id, 2)"
              >
                删除
              </vh-button>
              <vh-button
                v-if="WEBINAR_PES['ui.record_chapter'] && !scope.row.is_rehearsal"
                type="text"
                @click="toChapter(scope.row)"
              >
                章节
              </vh-button>
              <vh-button
                type="text"
                v-if="
                  ($route.meta.name == 'recordplayback' || $route.meta.name == 'publishplayback') &&
                  !scope.row.is_rehearsal
                "
                @click="encryption(scope.row)"
              >
                加密
              </vh-button>
              <vh-dropdown v-if="!isDemand && !scope.row.is_rehearsal" @command="handleCommand">
                <vh-button type="text">更多</vh-button>
                <vh-dropdown-menu style="width: 160px" slot="dropdown">
                  <vh-dropdown-item
                    v-if="
                      WEBINAR_PES['reset_record'] &&
                      !scope.row.layout &&
                      scope.row.is_union_screen != 1
                    "
                    :disabled="scope.row.source == 2"
                    :command="{ command: 'vodreset', data: scope.row }"
                  >
                    重制
                  </vh-dropdown-item>
                  <vh-dropdown-item
                    v-if="!scope.row.layout"
                    :command="{ command: 'tailoring', data: scope.row }"
                  >
                    剪辑
                  </vh-dropdown-item>
                  <vh-dropdown-item
                    v-if="WEBINAR_PES['publish_record'] && !scope.row.layout"
                    :command="{ command: 'publish', data: scope.row }"
                  >
                    发布
                  </vh-dropdown-item>
                  <vh-dropdown-item
                    v-if="!scope.row.layout || scope.row.layout != 0"
                    :command="{ command: 'record.encrypt', data: scope.row }"
                  >
                    加密
                  </vh-dropdown-item>
                  <vh-dropdown-item :command="{ command: 'delete', data: scope.row }">
                    删除
                  </vh-dropdown-item>
                </vh-dropdown-menu>
              </vh-dropdown>
              <vh-dropdown
                v-else-if="WEBINAR_PES['webinar.timing'] && liveDetailInfo.is_demand == 1"
                @command="handleCommand"
              >
                <vh-button type="text">更多</vh-button>
                <vh-dropdown-menu style="width: 160px" slot="dropdown">
                  <vh-dropdown-item :command="{ command: 'publishVideo', data: scope.row }">
                    发布
                  </vh-dropdown-item>
                </vh-dropdown-menu>
              </vh-dropdown>
            </template>
          </vh-table-column>
        </vh-table>
        <SPagination
          :total="totalElement"
          :page-size="pageSize"
          :current-page="pageNum"
          @current-change="currentChangeHandler"
          @size-change="sizeChange"
        ></SPagination>
        <null-page text="未搜索到相关内容" nullType="search" v-if="totalElement === 0"></null-page>
      </div>
    </template>

    <!-- 编辑弹窗 -->
    <vh-dialog
      title="编辑"
      v-loading="editLoading"
      :visible.sync="editDialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="480px"
    >
      <vh-input
        placeholder="请输入标题"
        :maxlength="100"
        autocomplete="off"
        v-clearEmoij
        :autosize="{ minRows: 3 }"
        resize="none"
        show-word-limit
        v-model="titleEdit"
        class="input-with-select"
        type="textarea"
      ></vh-input>
      <span slot="footer" class="dialog-footer">
        <vh-button
          type="info"
          @click="editDialogVisible = false"
          :disabled="editLoading"
          round
          plain
          size="medium"
        >
          取消
        </vh-button>
        <vh-button type="primary" @click="confirmEdit" :disabled="editLoading" round size="medium">
          确定
        </vh-button>
      </span>
    </vh-dialog>

    <!-- 发布弹窗 -->
    <vh-dialog
      title="发布回放"
      :visible.sync="publishDialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="580px"
    >
      <div class="publish-container">
        <div
          class="item"
          :class="activeIndex == 1 ? 'active' : 'unactive'"
          @click="activeIndex = 1"
        >
          <p class="item-img"><img src="./images/vod@2x.png" alt="" /></p>
          <div class="item-text">
            <p>发布为点播</p>
            <span>不支持定时直播和不支持助理的管理权限</span>
          </div>
        </div>
        <div
          class="item"
          :class="activeIndex == 2 ? 'active' : 'unactive'"
          @click="activeIndex = 2"
        >
          <p class="item-img"><img src="./images/timing@2x.png" alt="" /></p>
          <div class="item-text">
            <p>发布为定时直播</p>
            <span>支持定时直播和助理的管理权限</span>
          </div>
        </div>
      </div>
      <div class="dialog-publish-footer">
        <vh-button type="primary" round size="medium" @click="publishVideo">立即发布</vh-button>
      </div>
    </vh-dialog>
    <!-- 预览功能 -->
    <template v-if="showDialog">
      <vh-dialog
        custom-class="dialog-padding_playbackpreview"
        class="vh-saas-dialog"
        :visible.sync="showDialog"
        width="1010px"
        :before-close="closeBefore"
        center
        :close-on-press-escape="false"
      >
        <video-preview
          ref="videoPreview"
          :recordId="videoParamId"
          :webinarId="webinar_id"
        ></video-preview>
      </vh-dialog>
    </template>
    <begin-play
      :webinarId="$route.params.str"
      v-if="webinarState != 4 && $route.query.type != 5"
    ></begin-play>
    <!-- 上传回放视频 -->
    <PlaybackUpload ref="playbackUpload" @search="getList"></PlaybackUpload>

    <!-- 下载弹窗 -->
    <vh-dialog
      title="下载"
      :visible.sync="downloadVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="480px"
    >
      <div
        class="download-container"
        :class="{
          'justify-center': downloadData.status != 0,
          h_no_start: downloadData.status == 0
        }"
      >
        <div class="typeLogo">
          <i class="iconfont-v3 saasyinpinwenjian" v-if="[1].includes(webinarType)" />
          <i class="iconfont-v3 saasshipinwenjian" v-if="[2, 3, 6].includes(webinarType)" />
        </div>
        <p class="download_name">{{ downloadData.name }}</p>
        <vh-progress
          class="progress_wid"
          :percentage="downloadData.progress"
          :color="customColorMethod"
          :status="
            downloadData.status == 1
              ? 'success'
              : downloadData.status == 3
              ? 'exception'
              : undefined
          "
          v-if="[1, 2, 3].includes(downloadData.status)"
        ></vh-progress>
        <p
          class="download_progress_tip"
          :class="{ over_color: [1, 3].includes(downloadData.status) }"
          v-if="downloadData.statusText"
        >
          {{ downloadData.statusText }}
        </p>
        <vh-button
          type="primary"
          round
          size="medium"
          @click="transcodingStart"
          v-if="downloadData.status == 0"
        >
          开始转码
        </vh-button>
      </div>

      <div class="dialog-download-footer" :class="{ ing_height: downloadData.status == 2 }">
        <div align="center" v-if="[3].includes(downloadData.status)">
          <vh-button type="primary" round size="medium" @click="transcodingStart">
            开始转码
          </vh-button>
        </div>

        <div align="center" v-if="downloadData.download_url">
          <vh-button type="primary" round size="medium" @click="downloadVideo">立即下载</vh-button>
        </div>
      </div>
      <div class="download_tip_base" v-if="downloadData.status == 0">
        <p>提示</p>
        <p>1.视频时间越长，转码时间越长，请耐心等待</p>
        <p>2.转码成功后，可下载视频至本地电脑</p>
      </div>
    </vh-dialog>
  </div>
</template>

<script>
  import VideoPreview from './components/previewVideo';
  import PlaybackUpload from './components/playbackUpload';
  import PageTitle from '@/components/PageTitle';
  import { sessionOrLocal, isEmbed } from '@/utils/utils';
  import NullPage from '../../PlatformModule/Error/nullPage.vue';
  import beginPlay from '@/components/beginBtn';
  import tableCellTooltip from '@/components/TableList/mixins/tableCellTooltip';
  import { formateSeconds } from '@/utils/general';
  import { sizeTostr } from '@/utils/filter';

  export default {
    name: 'PlaybackList',
    mixins: [tableCellTooltip],
    data() {
      return {
        isEmbed: isEmbed(),
        lowerGradeInterval: null,
        // 预览
        showDialog: false,
        webinarState: JSON.parse(sessionOrLocal.get('webinarState')),
        videoParamId: '',
        userId: JSON.parse(sessionOrLocal.get('userId')),
        tableData: [],
        defaultImg: require('../../../common/images/v35-webinar.png'),
        keyWords: '',
        pageSize: 10,
        pageNum: 1,
        loading: false,
        totalElement: 0,
        editDialogVisible: false,
        titleEdit: '',
        editRecord: {},
        editLoading: false,
        selectDatas: [],
        transcodingArr: [],
        encryptingArr: [],
        recordType: '-1',
        liveDetailInfo: {},
        isDemand: '',
        chatSDK: '',
        handleMsgTimer: '',
        no_show: '',
        typeOptions: [
          { label: '全部来源', value: '-1' },
          { label: '回放', value: '0' },
          { label: '录制', value: '1' },
          { label: '上传', value: '2' },
          { label: '打点录制', value: '3' }
        ],
        // 权限配置
        WEBINAR_PES: {},
        OLDWEBINAR_PES: {},
        isBidScreen: true,
        versionExpired: false, // 用户套餐是否过期
        publishDialogVisible: false,
        activeIndex: 1, //默认激活点播
        recordData: {}, //发布时数据
        // WEBINAR_PES: sessionOrLocal.get('WEBINAR_PES', 'localStorage') && JSON.parse(sessionOrLocal.get('WEBINAR_PES', 'localStorage')) || {},
        downloadVisible: false,
        downloadData: {}
      };
    },
    computed: {
      pos() {
        return (this.pageNum - 1) * this.pageSize;
      },
      webinar_id() {
        return this.$route.params.str;
      },
      title() {
        if (this.isDemand === '') {
          return '';
        } else if (this.isDemand) {
          return this.liveDetailInfo.webinar_type == 5 ? '视频管理与AI创作' : '点播管理与AI创作';
        } else {
          return '回放管理与AI创作';
        }
      },
      webinarType() {
        return this.liveDetailInfo && this.liveDetailInfo.webinar_type;
      },
      // 显示下载按钮: 如果是子账号还需要有下载权限  点播定时直播需要admin权限
      showDownloadButton() {
        return !(
          ((this.liveDetailInfo.webinar_state == 4 ||
            [5, '5'].includes(this.liveDetailInfo.webinar_type)) &&
            !this.WEBINAR_PES['download_demand']) ||
          (this.childPremission && Number(this.childPremission.permission_content_download) === 0)
        );
      },
      childPremission: function () {
        return sessionOrLocal.get('SAAS_V3_SON_PS')
          ? JSON.parse(sessionOrLocal.get('SAAS_V3_SON_PS'))
          : {};
      }
    },
    created() {
      this.getList();
      this.getLiveDetail();
      this.$EventBus.$on('record_download', this.handleDownload);
      this.$EventBus.$on('vod_download_progress', this.transcodingProgress);
      this.$EventBus.$on('vod_remake_doc_progress', this.docProgress);
      this.$EventBus.$on('encrypt_complete', this.handleEncryptCallback);
      this.getPermission(this.$route.params.str);
      this.getVersion();
    },
    mounted() {
      window.addEventListener('resize', this.calcScreenWidth);

      this.$EventBus.$on('vod_cut', res => {
        //不是自己的消息不处理 || status ==1 剪辑成功
        if (this.userId != res.user_id || res.status != 1) {
          return;
        }
        // 转码状态
        console.log(res, '监听到vod_cut未读消息提示事件');
        this.tableData.map((item, index) => {
          // 0 转码中 1转码成功 2转码失败
          if (res.record_id == item.id) {
            if (res.status == 1) {
              item.transcode_status = 1;
              item.percentage = undefined;
              item.duration = formateSeconds(res.duration);
              item.transcode_status_text = '转码成功';
              item.storage = res.storage ? sizeTostr(res.storage * 1024) : '--';
            } else {
              item.percentage = undefined;
              item.transcode_status = 2;
              item.duration = '——';
              item.transcode_status_text = '转码失败';
            }
            this.$nextTick(() => {
              this.$set(this.tableData, index, Object.assign({}, item));
            });
          }
        });
        // this.getTableList();
      });

      this.$EventBus.$on('sign_trans_code', res => {
        //不是自己的消息不处理
        if (this.userId != res.user_id) {
          return;
        }
        // 转码状态
        console.log(res, '监听到sign_trans_code未读消息提示事件');
        this.tableData.map((item, index) => {
          // 0 转码中 1转码成功 2转码失败
          if (res.record_id == item.id) {
            if (res.status == 1) {
              item.transcode_status = 1;
              item.percentage = undefined;
              item.duration = formateSeconds(res.duration);
              item.transcode_status_text = '转码成功';
              item.storage = res.storage ? sizeTostr(res.storage * 1024) : '--';
            } else if (res.status == 0) {
              item.transcode_status = 0;
              item.percentage = res.progress;
              item.transcode_status_text = '转码中';
            } else {
              item.percentage = undefined;
              item.transcode_status = 2;
              item.duration = '——';
              item.transcode_status_text = '转码失败';
            }
            this.$set(this.tableData, index, Object.assign({}, item));
          }
        });
        // this.getTableList();
      });
    },
    beforeDestroy() {
      if (!this.WEBINAR_PES['forbid_delrecord'] || !this.WEBINAR_PES['publish_record']) {
        this.tipMsg && this.tipMsg.close();
      }
      if (this.chatSDK) {
        this.chatSDK.destroy();
        this.chatSDK = null;
      }
      if (this.lowerGradeInterval) clearInterval(this.lowerGradeInterval);
      this.$EventBus.$off('record_download', this.handleDownload);
      this.$EventBus.$off('vod_download_progress', this.transcodingProgress);
      this.$EventBus.$off('vod_remake_doc_progress', this.docProgress);
      this.$EventBus.$off('encrypt_complete', this.handleEncryptCallback);
      window.removeEventListener('resize', this.calcScreenWidth);
    },
    methods: {
      getVersion() {
        this.$fetch('getVersionInfo', { user_id: this.userId })
          .then(res => {
            this.versionExpired = res.data.expired == 1;
          })
          .catch(e => {
            console.log(e);
          });
      },
      calcScreenWidth() {
        const clientWidth = document.body.clientWidth;
        if (this.isDemand) return;
        if (clientWidth < 1900) {
          this.isBidScreen = false;
        } else {
          this.isBidScreen = true;
        }
      },
      handleTipMsgVisible() {
        let that = this;
        if (!this.WEBINAR_PES['forbid_delrecord']) {
          this.tipMsg = this.$vhMessage({
            dangerouslyUseHTMLString: true,
            type: 'warning',
            message: `
            ${
              this.WEBINAR_PES['forbid_delrecord']
                ? ''
                : '<span class="msgGray">非默认回放暂存15天</span>'
            }
            ${
              !this.WEBINAR_PES['forbid_delrecord'] && !this.WEBINAR_PES['publish_record']
                ? '，'
                : ''
            }
            ${
              this.WEBINAR_PES['publish_record']
                ? ''
                : "<span class='msgBlue' id='msgBlue'>开通点播服务</span>"
            }
          `,
            showClose: true,
            duration: 0
          });
          let open = document.querySelector('#msgBlue');
          open &&
            open.addEventListener('click', function (e) {
              let url = 'https://vhall.s4.udesk.cn/im_client/?web_plugin_id=15038';
              that.$vhall_paas_port({
                k: 100017,
                data: {
                  business_uid: that.userId,
                  user_id: '',
                  webinar_id: that.$route.params.str,
                  s: '',
                  refer: 2,
                  report_extra: {},
                  ref_url: '',
                  req_url: ''
                }
              });
              window.open(url, '_blank');
            });
        }
      },
      goReportData() {
        this.$vhall_paas_port({
          k: 100017,
          data: {
            business_uid: this.userId,
            user_id: '',
            webinar_id: this.$route.params.str,
            s: '',
            refer: 2,
            report_extra: {},
            ref_url: '',
            req_url: ''
          }
        });
      },
      getPermission(id) {
        // 活动权限
        this.$fetch('planFunctionGet', {
          webinar_id: id,
          webinar_user_id: this.userId,
          scene_id: 1
        })
          .then(res => {
            if (res.code == 200) {
              if (res.data.permissions) {
                sessionOrLocal.set('WEBINAR_PES', res.data.permissions, 'localStorage');
                this.WEBINAR_PES = JSON.parse(res.data.permissions);
                console.log('之前旧的权限', this.WEBINAR_PES);
                // this.handleLowerGradeHeart();
              } else {
                sessionOrLocal.removeItem('WEBINAR_PES');
              }
            } else {
              sessionOrLocal.removeItem('WEBINAR_PES');
            }
          })
          .catch(e => {
            console.log(e);
            sessionOrLocal.removeItem('SAAS_VS_PES');
          });
      },
      preview(data) {
        //  this.videoParam 进本信息
        if (data.transcode_status == 1) {
          this.showDialog = true;
          this.videoParamId = data.id;
        } else {
          this.$vhMessage.warning('只有生成成功才能查看');
        }
      },
      reTranscode(data) {
        if (data.transcode_status == 2 && data.layout != 0) {
          this.$vhMessage({
            message: `检测到当前视频未演示文档导致课件重制功能无法正常使用，请使用其他视频`,
            showClose: true,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return false;
        }
        this.$fetch('recordReTranscode', {
          paas_record_id: data.paas_record_id,
          webinar_id: this.webinar_id
        }).then(res => {
          this.$vhMessage({
            message: `正在重新生成，请稍后...`,
            showClose: true,
            type: 'success',
            customClass: 'zdy-info-box'
          });
          this.getList();
        });
      },
      closeBefore(done) {
        // this.$refs.videoPreview.destroy();
        done();
      },
      // 获取当前活动基本信息 判断是点播还是直播回放
      getLiveDetail() {
        // webinar/info调整-与活动状态无关的调用
        this.$fetch('getWebinarInfo', { webinar_id: this.webinar_id })
          .then(res => {
            this.liveDetailInfo = res.data;
            if (this.liveDetailInfo.webinar_type == 5 && !this.liveDetailInfo.is_demand) {
              this.isDemand = true;
            } else {
              this.isDemand = this.liveDetailInfo.is_demand == 1;
            }

            this.calcScreenWidth();
            if (this.isDemand) {
              this.recordType = '上传';
              this.typeOptions = [{ label: '上传', value: '2' }];
            } else {
              this.handleTipMsgVisible();
              this.typeOptions = [
                { label: '全部来源', value: '-1' },
                { label: '回放', value: '0' },
                { label: '录制', value: '1' },
                { label: '上传', value: '2' },
                { label: '打点录制', value: '3' }
              ];
            }
          })
          .catch(res => {
            this.$vhMessage({
              message: res.msg || '活动详情',
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          })
          .finally(() => {
            this.loading = false;
          });
      },
      typeChange(column, index) {
        this.getList();
      },
      setDefault(row) {
        console.log(row);
        // 判断视频是否为加密中
        if (row.encrypt_status == '1') {
          let msg = '视频加密中，请加密完成后使用此功能';
          this.$vhMessage.warning(msg);
          return;
        }
        const confirmTitle =
          row.type === 6 ? '取消默认回放后，回放将不能观看' : '设置为默认回放后，将无法进行预约';
        this.$vhConfirm(confirmTitle, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          roundButton: true,
          customClass: 'zdy-message-box',
          lockScroll: false,

          cancelButtonClass: 'zdy-confirm-cancel'
        }).then(() => {
          this.$fetch('defaultRecord', {
            webinar_id: this.webinar_id,
            record_id: row.id,
            type: row.type === 6 ? 0 : 1
          }).then(res => {
            console.log(res);
            // TODO: 刷新列表
            this.getList();
          });
        });
      },
      handleSelectionChange(val) {
        this.selectDatas = val;
      },
      handleCommand(param) {
        // 判断是否为加密视频
        if (
          param.data.encrypt_status == '2' &&
          param.command != 'delete' &&
          param.command != 'record.encrypt'
        ) {
          let msg = '加密视频不支持使用此功能';
          this.$vhMessage.warning(msg);
          return;
        }
        // 判断视频是否为加密中
        if (param.data.encrypt_status == '1' && param.command != 'delete') {
          let msg = '视频加密中，请加密完成后使用此功能';
          this.$vhMessage.warning(msg);
          return;
        }
        // 判断视频是否为转码中
        if (!param.data.transcode_status && param.command != 'delete') {
          let msg = '视频转码中，不支持使用加密功能';
          this.$vhMessage.warning(msg);
          return;
        }
        if (param.command == 'delete') {
          this.deletePlayBack(param.data.id, 2);
        } else if (param.command == 'tailoring') {
          this.$vhall_paas_port({
            k: 100411,
            data: {
              business_uid: this.userId,
              user_id: '',
              webinar_id: this.$route.params.str,
              s: '',
              refer: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
          this.toTailoring(param.data.id, param.data.name);
        } else if (param.command == 'publish') {
          this.$vhall_paas_port({
            k: 100413,
            data: {
              business_uid: this.userId,
              user_id: '',
              webinar_id: this.$route.params.str,
              s: '',
              refer: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
          this.toCreateDemand(param.data);
        } else if (param.command == 'vodreset') {
          this.$vhall_paas_port({
            k: 100415,
            data: {
              business_uid: this.userId,
              user_id: '',
              webinar_id: this.$route.params.str,
              s: '',
              refer: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
          this.toVodreset(param.data);
        } else if (param.command == 'publishVideo') {
          this.$vhall_paas_port({
            k: 100413,
            data: {
              business_uid: this.userId,
              user_id: '',
              webinar_id: this.$route.params.str,
              s: '',
              refer: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
          //发布为定时直播
          this.recordData = param.data;
          this.publishVideoPre(2);
        }
        // 加密按钮
        if (param.command == 'record.encrypt') {
          this.encryption(param.data);
        }
      },
      currentChangeHandler(num) {
        this.pageNum = num;
        this.getList();
      },
      sizeChange(num) {
        this.pageNum = 1;
        this.pageSize = num;
        this.getList();
      },
      getSearchList() {
        if (this.keyWords) {
          this.$vhall_paas_port({
            k: 100418,
            data: {
              business_uid: this.userId,
              user_id: '',
              webinar_id: this.$route.params.str,
              s: '',
              refer: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
        }
        this.getList();
      },
      getList() {
        let param = {
          webinar_id: this.webinar_id,
          user_id: this.userId,
          pos: this.pos,
          limit: this.pageSize,
          source: this.recordType
        };
        param.source == '上传' && (param.source = -1);
        this.keyWords && (param.name = this.keyWords);
        this.loading = true;
        this.$fetch('playBackList', param)
          .then(res => {
            res.data.list.forEach(item => {
              item.transcoding = false;
            });
            this.tableData = res.data.list;
            console.log('this.tableData:', this.tableData);
            this.totalElement = res.data.total;
            if (this.no_show === '') {
              this.no_show = res.data.total == 0 ? true : false;
            }
          })
          .catch(res => {
            this.$vhMessage({
              message: res.msg || `获取回放列表失败`,
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          })
          .finally(() => {
            this.loading = false;
          });
        // playBackList
      },
      editDialog(data) {
        this.titleEdit = data.name;
        this.editDialogVisible = true;
        this.editRecord = data;
      },
      handleEncryptCallback(data) {
        // data
        // 'type'      => 'entry_trans',
        // 'user_id'   => 视频资源对应用户id,
        // 'status'    => 1 成功 2:失败,
        // 'record_id' => 视频资源id
        console.log('视频加密完成');
        this.encryptingArr = this.encryptingArr.filter(item => {
          if (item.id == data.record_id) {
            item.encrypt_status = '2';
            return false;
          }
        });
      },
      handleDownload(data) {
        console.log('视频转码完成了');
        // 消息会下发三次，只处理第一次
        if (!this.handleMsgTimer) {
          this.transcodingArr = this.transcodingArr.filter(item => {
            if (item.id == data.record_id) {
              item.transcoding = false;
              return false;
            }
          });
          if (data.record_id == this.downloadData.record_id) {
            this.downloadData.status = data.status;
            if (data.status == 3) {
              this.downloadData.statusText = '转码失败，请重试';
            }
            if (data.download_url) {
              this.downloadData.progress = 100;
              this.downloadData.download_url = data.download_url;
              this.downloadData.statusText = '转码完成';
            }
            console.log(this.downloadData, 'this.downloadData');
            this.$forceUpdate();
          }
          // window.open(data.download_url);
          this.handleMsgTimer = setTimeout(() => {
            this.handleMsgTimer = '';
          }, 2000);
        }
      },
      // 下载回放
      downPlayBack(data) {
        // 判断视频是否为加密中
        if (data.encrypt_status == '1') {
          let msg = '视频加密中，请加密完成后使用此功能';
          this.$vhMessage.warning(msg);
          return;
        }
        if (this.versionExpired) {
          this.$vhConfirm(
            '尊敬的用户，您的账号已到期。为了保证正常使用，请联系您的客户经理或专属售后，也可拨打400-888-9970转2咨询',
            '提示',
            {
              confirmButtonText: '我知道了',
              showCancelButton: false,
              roundButton: true,
              customClass: 'zdy-message-box',
              lockScroll: false,

              cancelButtonClass: 'zdy-confirm-cancel'
            }
          )
            .then(() => {})
            .catch(() => {});
          return false;
        }

        // const fetchCb = () => {
        //   this.$fetch('playBackDownUrlGet', {
        //     record_id: data.id
        //   }).then(res => {
        //     console.log(res);
        //     this.$vhall_paas_port({
        //       k: 100409,
        //       data: {
        //         business_uid: this.userId,
        //         user_id: '',
        //         webinar_id: this.$route.params.str,
        //         s: '',
        //         refer: '',
        //         report_extra: {},
        //         ref_url: '',
        //         req_url: ''
        //       }
        //     });
        //     if (res.data.has_download_url == 0) {
        //       data.transcoding = true;
        //       this.transcodingArr.push(data);
        //       this.$vhMessage({
        //         message: `正在生成，请稍侯...`,
        //         showClose: true,
        //         // duration: 0,
        //         type: 'success',
        //         customClass: 'zdy-info-box'
        //       });
        //       return false;
        //     }
        //     window.open(res.data.download_url);
        //   });
        // };
        this.checkTransStatus(data.id, this.getVideoStatus);
      },
      deletePlayBack(ids, index) {
        this.$vhConfirm(
          '删除回放会导致目前已生成回放的数据丢失，请谨慎操作，确定要删除这段回放么？',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            roundButton: true,
            customClass: 'zdy-message-box',
            lockScroll: false,

            cancelButtonClass: 'zdy-confirm-cancel'
          }
        )
          .then(() => {
            this.loading = true;
            this.$fetch('playBackDelete', { record_ids: ids })
              .then(res => {
                this.$vhall_paas_port({
                  k: index === 1 ? 100416 : 100417,
                  data: {
                    business_uid: this.userId,
                    user_id: '',
                    webinar_id: this.$route.params.str,
                    s: '',
                    refer: '',
                    report_extra: {},
                    ref_url: '',
                    req_url: ''
                  }
                });
                this.$vhMessage({
                  message: `已删除`,
                  showClose: true,
                  // duration: 0,
                  type: 'success',
                  customClass: 'zdy-info-box'
                });
                this.getList();
              })
              .catch(res => {
                this.$vhMessage({
                  message: res.msg || `删除失败`,
                  showClose: true,
                  // duration: 0,
                  type: 'error',
                  customClass: 'zdy-info-box'
                });
                this.loading = false;
              });
          })
          .catch(() => {});
      },
      confirmEdit() {
        if (!this.titleEdit.trim())
          return this.$vhMessage({
            message: `请输入标题`,
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
        this.editLoading = true;
        this.$fetch('playBackEdit', { record_id: this.editRecord.id, name: this.titleEdit })
          .then(res => {
            this.$vhall_paas_port({
              k: 100408,
              data: {
                business_uid: this.userId,
                user_id: '',
                webinar_id: this.$route.params.str,
                s: '',
                refer: '',
                report_extra: {},
                ref_url: '',
                req_url: ''
              }
            });
            this.$vhMessage({
              message: `修改成功`,
              showClose: true,
              // duration: 0,
              type: 'success',
              customClass: 'zdy-info-box'
            });
            this.getList();
            this.editDialogVisible = false;
          })
          .catch(res => {
            this.$vhMessage({
              message: res.msg || `修改失败`,
              showClose: true,
              // duration: 0,
              type: 'error',
              customClass: 'zdy-info-box'
            });
          })
          .finally(() => {
            this.editLoading = false;
          });
      },
      settingHandler() {
        this.$vhall_paas_port({
          k: 100407,
          data: {
            business_uid: this.userId,
            user_id: '',
            webinar_id: this.$route.params.str,
            s: '',
            refer: '',
            report_extra: {},
            ref_url: '',
            req_url: ''
          }
        });
        this.$router.push({
          path: `${this.isEmbed ? '/embed' : ''}/live/planFunction/${this.webinar_id}`
        });
      },
      toVodreset(data) {
        if (this.versionExpired) {
          this.$vhConfirm(
            '尊敬的用户，您的账号已到期。为了保证正常使用，请联系您的客户经理或专属售后，也可拨打400-888-9970转2咨询',
            '提示',
            {
              confirmButtonText: '我知道了',
              // cancelButtonText: '暂不关闭',
              showCancelButton: false,
              roundButton: true,
              customClass: 'zdy-message-box',
              lockScroll: false,

              cancelButtonClass: 'zdy-confirm-cancel'
            }
          )
            .then(() => {})
            .catch(() => {});
          return false;
        }
        const routerPush = () => {
          this.$router.push({
            path: `${this.isEmbed ? '/embed' : ''}/live/vodreset/${this.webinar_id}`,
            query: {
              record_id: data.id
            }
          });
        };
        this.checkTransStatus(data.id, routerPush);
      },
      toCreate() {
        this.$router.push({
          path: `/videoTailoring/${this.webinar_id}`,
          query: {
            from: this.isEmbed ? 'embed' : ''
          }
        });
        // const routeData = this.$router.resolve({path: `/videoTailoring/${this.webinar_id}`});
        // window.open(routeData.href, '_blank');
      },
      toRecord() {
        this.$fetch('recordCheck', {
          webinar_id: this.webinar_id
        })
          .then(res => {
            if (res.code == 200) {
              // this.$router.push({path: `/live/recordvideo/${this.webinar_id}`});
              window.location.href = `${window.location.origin}${process.env.VUE_APP_WEB_KEY}/lives/recordvideo/${this.webinar_id}`;
            }
          })
          .catch(err => {
            if (err.code == 512550) {
              this.$vhMessage.warning('该活动正在直播或录制中，无法重复发起');
            } else {
              this.$vhMessage.warning(err.msg);
            }
          });
      },
      toTailoring(recordId, recordName) {
        const routerPush = () => {
          this.$router.push({
            path: `/videoTailoring/${this.webinar_id}`,
            query: { recordId, recordName, from: this.isEmbed ? 'embed' : '' }
          });
        };
        this.checkTransStatus(recordId, routerPush);
      },
      checkTransStatus(recordId, cb) {
        this.$fetch('recordInfo', {
          record_id: recordId
        }).then(res => {
          if (res.data.transcode_status == 0 || res.data.transcode_status == 3) {
            this.$vhMessage({
              message: '视频生成中，请在成功后使用',
              showClose: true, // 是否展示关闭按钮
              type: 'warning', //  提示类型
              customClass: 'zdy-info-box' // 样式处理
            });
          } else if (res.data.transcode_status == 2) {
            this.$vhMessage({
              message: '视频生成失败',
              showClose: true, // 是否展示关闭按钮
              type: 'error', //  提示类型
              customClass: 'zdy-info-box' // 样式处理
            });
          } else {
            cb && cb();
          }
        });
      },
      async toChapter(row) {
        // 判断视频是否为加密中
        if (row.encrypt_status == '1') {
          let msg = '视频加密中，请加密完成后使用此功能';
          this.$vhMessage.warning(msg);
          return;
        }
        this.$vhall_paas_port({
          k: 100410,
          data: {
            business_uid: this.userId,
            user_id: '',
            webinar_id: this.$route.params.str,
            s: '',
            refer: '',
            report_extra: {},
            ref_url: '',
            req_url: ''
          }
        });
        const recordId = row.id;
        const chapterType = this.isDemand ? 'recordchapter' : 'chapter';
        // 如果回放转码完成，并且支持章节功能或者是点播活动，直接跳转
        if (this.isDemand || (row.transcode_status == 1 && row.doc_status)) {
          await this.checkChapterSave(recordId, chapterType);
          return false;
        }
        // 如果是上传音视频
        if (row.source == 2) {
          this.$router.push({
            path: `/${chapterType}/${this.webinar_id}`,
            query: {
              recordId,
              isDemand: true,
              pageKey: this.$route.meta.name,
              type: this.liveDetailInfo.webinar_type,
              from: this.isEmbed ? 'embed' : ''
            }
          });
          return;
        }
        // 如果回放未转码完成，点击的时候需要获取最新的转码状态和是否支持章节功能
        this.$fetch('recordInfo', {
          record_id: recordId
        }).then(res => {
          if (res.data.transcode_status == 0 || res.data.transcode_status == 3) {
            this.$vhMessage({
              message: '视频生成中，请稍后再试',
              showClose: true, // 是否展示关闭按钮
              type: 'warning', //  提示类型
              customClass: 'zdy-info-box' // 样式处理
            });
          } else if (res.data.transcode_status == 2) {
            this.$vhMessage({
              message: '视频生成失败',
              showClose: true, // 是否展示关闭按钮
              type: 'error', //  提示类型
              customClass: 'zdy-info-box' // 样式处理
            });
          } else {
            this.$fetch('getDocInfo', {
              record_id: recordId
            }).then(res => {
              console.log(res);
              if (res.data.doc_titles.length) {
                this.checkChapterSave(recordId, chapterType);
              } else {
                this.$vhMessage({
                  message: '当前回放内容未演示PPT格式的文档，不支持使用章节功能',
                  showClose: true, // 是否展示关闭按钮
                  type: 'warning', //  提示类型
                  customClass: 'zdy-info-box' // 样式处理
                });
              }
            });
          }
        });
      },
      checkChapterSave(recordId, chapterType) {
        return this.$fetch('checkChapterSave', {
          record_id: recordId
        }).then(res => {
          if (res.data && res.data.chatper_callbanck_status == 0) {
            this.$vhMessage({
              message: '当前视频与文档正在关联中，请稍后再试',
              showClose: true, // 是否展示关闭按钮
              type: 'warning', //  提示类型
              customClass: 'zdy-info-box' // 样式处理
            });
          } else {
            this.$router.push({
              path: `/${chapterType}/${this.webinar_id}`,
              query: {
                recordId,
                isDemand: this.isDemand,
                pageKey: this.$route.meta.name,
                type: this.liveDetailInfo.webinar_type,
                from: this.isEmbed ? 'embed' : ''
              }
            });
            // const routeData = this.$router.resolve({path: `/${chapterType}/${this.webinar_id}`, query: {recordId, isDemand: this.isDemand}});
            // window.open(routeData.href, '_blank');
          }
        });
      },
      // 发布
      toCreateDemand(recordData) {
        this.recordData = recordData;
        if (this.WEBINAR_PES['webinar.timing'] == 1) {
          this.publishDialogVisible = true;
        } else {
          this.publishVodTiming(recordData, 1);
        }
      },

      // 发布为点播或定时直播
      publishVodTiming(recordData, index) {
        const url = index == 1 ? '/live/vodEdit' : '/live/timeEdit';
        const routerPush = () => {
          this.$router.push({
            path: `${this.isEmbed ? '/embed' : ''}${url}`,
            query: {
              record_id: recordData.id,
              paas_record_id: recordData.paas_record_id,
              name: recordData.name,
              webinar_id: this.webinar_id
            }
          });
        };
        this.checkTransStatus(recordData.id, routerPush);
      },
      publishVideo() {
        this.publishDialogVisible = false;
        this.publishVodTiming(this.recordData, this.activeIndex);
      },
      // 加密
      encryption(data) {
        // 判断视频是否为转码失败
        if (data.transcode_status == '2') {
          let msg = '视频转码失败，不支持使用加密功能';
          this.$vhMessage.warning(msg);
          return;
        }
        // 判断是否有加密权限
        if (this.WEBINAR_PES['record.encrypt'] != 1) {
          this.$vhConfirm(
            '尊敬的用户：您的账号未开通视频加密功能，请联系您的专属售后或拨打400-888-9970转2咨询',
            '提示',
            {
              confirmButtonText: '我知道了',
              showCancelButton: false,
              roundButton: true,
              customClass: 'zdy-message-box',
              lockScroll: false,

              cancelButtonClass: 'zdy-confirm-cancel'
            }
          )
            .then(() => {})
            .catch(() => {});
          return false;
        }
        // 判断视频是否已加密
        if (data.encrypt_status == '2') {
          let msg = '当前视频已是加密视频';
          this.$vhMessage.warning(msg);
          return;
        }
        // 判断视频是否为加密中
        if (data.encrypt_status == '1') {
          let msg = '视频加密中，请加密完成后使用此功能';
          this.$vhMessage.warning(msg);
          return;
        }
        this.$vhConfirm(
          '视频一旦加密不支持取消加密，且不支持使用重制、剪辑、发布等功能，确定生成加密视频？',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButton: '取消',
            customClass: 'zdy-message-box',
            lockScroll: false,
            roundButton: true,

            cancelButtonClass: 'zdy-confirm-cancel'
          }
        )
          .then(() => {
            this.$fetch('checkChapterSave', {
              record_id: data.id
            }).then(res => {
              if (res.data && res.data.chatper_callbanck_status == 0) {
                this.$vhMessage({
                  message: '当前视频与文档正在关联中，请稍后再试',
                  showClose: true, // 是否展示关闭按钮
                  type: 'warning', //  提示类型
                  customClass: 'zdy-info-box' // 样式处理
                });
              } else {
                this.encrypyStart(data);
              }
            });
          })
          .catch(() => {});
      },
      encrypyStart(data) {
        this.$fetch('recordEncrypt', {
          paas_record_id: data.paas_record_id
        }).then(res => {
          if (res.code == 200) {
            data.encrypt_status = '1';
            this.encryptingArr.push(data);
          }
        });
      },
      // 加密介绍
      openTip() {
        window.open('https://saas-doc.vhall.com/opendocs/show/1382');
      },
      // 上传音视频
      uploadBut() {
        this.$refs['playbackUpload'].dialogVisible = true;
        this.$refs['playbackUpload'].getSearchList('search');
      },
      // 获取视频状态
      getVideoStatus(data) {
        this.$fetch('playBackDownUrlGet', {
          record_id: data.id
        }).then(res => {
          console.log(res);
          this.$vhall_paas_port({
            k: 100409,
            data: {
              business_uid: this.userId,
              user_id: '',
              webinar_id: this.$route.params.str,
              s: '',
              refer: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
          // if (res.data.has_download_url == 0) {
          //   data.transcoding = true;
          //   this.transcodingArr.push(data);
          //   this.$vhMessage({
          //     message: `正在生成，请稍侯...`,
          //     showClose: true,
          //     // duration: 0,
          //     type: 'success',
          //     customClass: 'zdy-info-box'
          //   });
          //   return false;
          // }
          // window.open(res.data.download_url);
          this.downloadData = {};
          Object.assign(this.downloadData, data, res.data);
          this.openDownload();
        });
      },
      // open download弹框
      openDownload(data) {
        this.downloadVisible = true;
        if (this.downloadData.download_url) {
          this.downloadData.statusText = '转码完成';
        }
        if (this.downloadData.status == 2) {
          this.downloadData.statusText = '视频转码中，请耐心等待...关闭弹窗，转码不中断';
        }
        if (this.downloadData.status == 3) {
          this.downloadData.statusText = '转码失败，请重试';
        }
      },
      // 开始转码
      transcodingStart() {
        this.downloadData.status = '';
        this.downloadData.statusText = '视频转码中，请耐心等待...关闭弹窗，转码不中断';

        this.$fetch('transBegin', {
          record_id: this.downloadData.record_id
        }).then(res => {
          this.downloadData.status = 2;
          this.$forceUpdate();
        });
      },
      // 转码进度监听
      transcodingProgress(data) {
        if (data.record_id == this.downloadData.record_id) {
          this.downloadData.progress = Number(data.progress);
          if (data.progress == 100) {
            this.downloadData.statusText = '转码完成';
          }
          this.$forceUpdate();
        }
      },
      // 重置进度监听
      docProgress(data) {
        this.tableData.forEach((i, index) => {
          if (i.id == data.record_id) {
            i.remake_doc_progress = data.progress;
            if (data.progress == 100) {
              i.transcode_status = 1;
            }
          }
        });
      },
      // 进度条颜色更改
      customColorMethod(percentage) {
        if (percentage < 99) {
          return '#FB2626';
        } else {
          return '#0FBA5A';
        }
      },
      // 视频下载
      downloadVideo() {
        window.open(this.downloadData.download_url);
      },
      publishVideoPre(index) {
        this.publishVodTiming(this.recordData, index);
      },
      handleAi(row) {
        console.log('row:', row);
        if (row.transcode_status != 1) {
          this.$vhMessage.warning('请视频转码成功后再使用此功能');
          return false;
        }
        if (row.encrypt_status == 1) {
          this.$vhMessage.warning('视频加密中，请加密完成后使用此功能');
          return false;
        }
        if (this.WEBINAR_PES['ai_creation'] !== 1) {
          this.$vhAlert(
            `AI创作为收费功能。请联系您的客户经理或专属售后，也可拨打400-888-9970转2咨询。
             <br><div class="alert-link "><a class="blue" target="_blank" href="https://saas-doc.vhall.com/opendocs/show/1614">查看功能介绍</a>
             &nbsp;&nbsp;&nbsp;<a class="blue" target="_blank" href="https://vhall.s4.udesk.cn/im_client/?web_plugin_id=15038">咨询在线客服</a></div>`,
            '提示',
            {
              confirmButtonText: '我知道了',
              confirmButtonClass: 'is-round',
              customClass: 'zdy-alert-box zdy-padding',
              dangerouslyUseHTMLString: true,
              // center: true,
              lockScroll: false,
              callback: () => {}
            }
          );
          return false;
        }
        if (this.versionExpired) {
          this.$vhAlert(
            '尊敬的用户，您的账号已到期。为了保证正常使用，请联系您的客户经理或专属售后，也可拨打400-888-9970转2咨询。&nbsp;&nbsp;<a class="blue" target="_blank" href="https://vhall.s4.udesk.cn/im_client/?web_plugin_id=15038">在线客服</a>',
            '提示',
            {
              confirmButtonText: '我知道了',
              confirmButtonClass: 'is-round',
              customClass: 'zdy-alert-box zdy-padding',
              dangerouslyUseHTMLString: true,
              // center: true,
              lockScroll: false,
              callback: () => {}
            }
          );
          return false;
        }
        if ((process.env.VUE_APP_AI_HOST || '').indexOf('localhost') > -1) {
          const token = sessionOrLocal.get('token', 'localStorage') || '';
          /* prettier-ignore */
          window.open(`${process.env.VUE_APP_AI_HOST}v3/saas-web-ai/video/parsing/${row.id}?token=${token}`, '_blank');
        } else {
          /* prettier-ignore */
          window.open(`${process.env.VUE_APP_AI_HOST}v3/saas-web-ai/video/parsing/${row.id}`, '_blank');
        }
      }
    },
    filters: {
      layoutFilter: function (layout) {
        if (layout == 0) {
          return '-';
        } else if (layout == 1) {
          return '三分屏';
        } else if (layout == 2) {
          return '纯文档';
        } else if (layout == 3) {
          return '画中画';
        }
      },
      secondsFormmat(val) {
        val = Number(val);
        if (isNaN(val)) return val;
        const hours = parseInt(val / 3600);
        const minutes = parseInt(val / 60) - hours * 60;
        const seconds = val % 60;
        return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${
          seconds < 10 ? `0${seconds}` : seconds
        }`;
      },
      soruceTotext(val) {
        //   0 回放 1 录制 2 上传 3 打点录制
        val = Number(val);
        let str = val;
        switch (val) {
          case 0:
            str = '回放';
            break;

          case 1:
            str = '录制';
            break;

          case 2:
            str = '上传';
            break;

          case 3:
            str = '打点';
            break;

          default:
            str = val;
            break;
        }
        return str;
      }
    },
    components: {
      PageTitle,
      VideoPreview,
      NullPage,
      beginPlay,
      PlaybackUpload
    }
  };
</script>

<style lang="less" scoped>
  .listBox {
    .btn-list .vh-button:last-child {
      margin-right: 0;
      margin-left: 0;
    }
    min-width: 1020px;
    .noData .createBtn {
      margin-right: 12px;
    }
  }
  .tableBox {
    padding: 24px 24px;
    background: #fff;
    /deep/ .deltime .cell {
      padding-left: 22px;
    }
    /deep/ .vh-table__empty-block {
      display: none;
    }
    /deep/ .cell {
      color: #666;
    }
    /deep/ .vh-table__row td:nth-child(2) .cell {
      padding-left: 10px;
    }
    /deep/&.recordTableBox .vh-table__row td:nth-child(3) .cell {
      padding-left: 10px;
    }
    /deep/ thead th:nth-child(2) .cell {
      padding-left: 10px;
    }
    /deep/&.recordTableBox thead th:nth-child(3) .cell {
      padding-left: 10px;
    }
    .vh-table {
      margin-bottom: 40px;
    }
    .playpackSource {
      color: #1a1a1a;
    }
    .vh-button.is-disabled {
      border: 0;
      color: #1a1a1a;
    }
    /deep/ thead .vh-select {
      .vh-input__inner {
        border: none;
        color: #666;
      }
      .vh-input__suffix {
        .vh-icon-arrow-up {
          color: #666;
          line-height: 36px;
          // height: 14px;
        }
      }
    }
  }
  /* /deep/ .vh-table__header{
    th{
      background: #F7F7F7;
    }
  } */
  .vh-dropdown {
    margin-left: 10px;
  }
  .content {
    .imageBox {
      position: relative;
      float: left;
      width: 140px;
      height: 79px;
      border-radius: 4px;
      background-color: #1a1a1a;
      .imageWrap {
        width: 100%;
        height: 100%;
        cursor: not-allowed;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 2;
        border-radius: 4px;
        .statusDesc {
          width: 80px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          color: #fff;
          font-size: 12px;
          background: #fb3a32;
          border-radius: 15px;
          cursor: pointer;
          &.disabled {
            cursor: default;
          }
        }
      }
      .defaultSign {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 80px;
        height: 22px;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 12px 0px 4px 0px;
        color: #fff;
        font-size: 12px;
        padding-left: 8px;
        &.isRehearsal {
          padding-left: 6px;
          padding-right: 6px;
          width: auto;
        }
        i {
          display: inline-block;
          width: 12px;
          height: 12px;
          border-radius: 8px;
          border: 1px solid #ffffff;
          margin-right: 4px;
          margin-bottom: -2px;
          cursor: pointer;
          &.active {
            border: 3px solid #fb3a32;
            background: #fff;
          }
        }
        // opacity: 0.66;
      }
      .image-slot img {
        cursor: pointer;
      }
    }
    .info {
      margin-left: 12px;
      font-size: 14px;
      width: 187px;
      color: #1a1a1a;
      float: left;
      .name {
        line-height: 20px;
        // height: 40px;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
        -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
        -webkit-line-clamp: 1; /** 显示的行数 **/
        overflow: hidden; /** 隐藏超出的内容 **/
      }
      .create-time {
        padding-top: 5px;
      }
      .tag {
        display: inline-block;
        padding: 0 8px;
        height: 20px;
        line-height: 20px;
        background: #ebefff;
        border-radius: 14px;
        font-size: 12px;
        text-align: center;
        color: #3562fa;
        margin-right: 5px;
      }
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      object-fit: scale-down;
    }
  }
  .operaBlock {
    margin-bottom: 20px;
    .vh-input {
      width: 220px;
      float: right;
      /deep/ .vh-input__inner {
        border-radius: 20px;
        height: 36px;
      }
      /deep/ .vh-input__prefix {
        cursor: pointer;
        /deep/ .vh-input__icon {
          line-height: 36px;
        }
      }
    }
    .resetRightBrn {
      /deep/ .vh-input__inner {
        border-radius: 20px;
        height: 36px;
        padding-right: 30px !important;
      }
      /deep/ .vh-input__icon {
        line-height: 36px;
      }
      /deep/ .vh-input__prefix {
        cursor: pointer;
      }
    }
  }
  .input-with-select {
    vertical-align: text-top;
  }
  /deep/ .dialog-padding_playbackpreview {
    padding: 0px 0px 30px;
    background: transparent !important;
    border: none;
    box-shadow: none;
    .vh-dialog__headerbtn {
      top: 24px;
      margin-bottom: 8px;
      .vh-dialog__close {
        color: #ffffff;
      }
    }
  }
  .ps {
    position: absolute;
  }
  .jiami {
    text-align: center;
    font-size: 12px;
    color: white;
    width: 40px;
    height: 22px;
    background: #fb3a32;
    border-radius: 4px 0px 12px 2px;
    top: 0;
    left: 0;
  }
  .jiamizhong {
    text-align: center;
    // font-size: 12px;
    color: white;
    width: 72px;
    height: 23px;
    background: #fb3a32;
    border-radius: 12px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .color999 {
    color: #999;
    font-size: 14px;
  }
  .jiami_zhezhao {
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
  }
  .publish-container {
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    .item {
      display: flex;
      padding: 28px 32px;
      border-radius: 4px;
      align-items: center;
      border: 1px solid transparent;
      &-img {
        width: 42px;
        height: 36px;
        img {
          width: 100%;
          height: 100%;
          object-fit: scale-down;
        }
      }
      &-text {
        padding-left: 10px;
        p {
          color: #1a1a1a;
          font-size: 16px;
          line-height: 25px;
        }
        span {
          color: #666;
          font-size: 14px;
          line-height: 20px;
        }
      }
      &:first-child {
        margin-bottom: 24px;
      }
      &.active {
        border: 1px solid #fb3a32;
      }
      &.unactive {
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
      }
      &:hover {
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
      }
    }
  }
  .dialog-publish-footer {
    padding: 32px 0;
    text-align: center;
  }
  .download_tip_base {
    width: 300px;
    text-align: left;
    margin: auto;
    color: #8c8c8c;
    font-size: 12px;
    line-height: 18px;
  }
  .justify-center {
    justify-content: center;
  }
  .download-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 186px;
    .typeLogo {
      margin-bottom: 8px;
      text-align: center;
      i {
        font-size: 40px;
        color: #10d3a8;
      }
      .saasshipinwenjian {
        color: #ff733c;
      }
    }
    p {
      width: 260px;
      margin: 0 auto;
      text-align: center;
      line-height: 20px;
    }
    .download_name {
      margin-bottom: 18px;
      overflow: hidden;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      color: #1a1a1a;
    }
    .progress_wid {
      width: 340px;
      margin-left: 15px;
      // /deep/.vh-progress-bar {
      //   padding-right: 20px;
      //   margin-right: -24px;
      // }
    }
    .download_progress_tip {
      width: 270px;
      margin-top: 10px;
      font-size: 12px;
      color: #8c8c8c;
    }
    .over_color {
      color: #262626;
    }
  }
  .h_no_start {
    height: 168px;
  }
  .ing_height {
    height: 36px;
  }
  .dialog-download-footer {
    text-align: center;
  }
</style>
<style lang="less">
  .alert-link {
    margin-top: 8px;
  }
  .blue {
    color: #3562fa;
    &:hover {
      cursor: pointer;
    }
  }
  .playback-list-popper {
    width: 120px;
  }
  .msgGray {
    color: #1a1a1a;
  }
  .msgBlue {
    color: #3562fa;
    cursor: pointer;
  }
  .vh-tooltip__popper {
    max-width: 376px;
  }
</style>
